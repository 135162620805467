.additional-revenue-history-popup {
  .modal-body {
    overflow-y: auto;

  }
  .modal{
    z-index: 10 !important;
  }
  .nav-pills {
    border-bottom: 1px solid rgba($text-color, 0.1);
    margin-bottom: 30px;
    padding-bottom: 8px;
  }

  .tab-line {
    .tab-list {
      .nav-item {
        .nav-link {
          font-size: 14px;
          padding-left: 10px;
          padding-right: 10px;
        }
      }
    }
  }

  .accordion__item {
    position: relative;



  }

  .accordion__button {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: rgba($text-color, 1);
    background: #F7F8FC;
    padding: 14px;
    border-radius: 8px;

    ul {
      display: flex;

      li {
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;
        padding-right: 20px;
        position: relative;

        &:first-child {
          &::after {
            content: '';
            height: 3px;
            width: 3px;
            border-radius: 100%;
            background-color: rgba($text-color, 0.3);
            position: absolute;
            top: 0px;

            margin-left: 8px;
            top: 9px;
          }
        }
      }
    }

    &:after {
      content: "\e907";
      font-family: dc !important;
      position: absolute;
      right: 14px;
      font-size: 20px;
      top: 11px;
    }

  }

  .accordion__button[aria-expanded="true"]::after,
  .accordion__button[aria-selected="true"]::after {
    content: "\e90c";
    font-family: dc !important;
  }

  .accordion__button[aria-expanded="true"],
  .accordion__button[aria-selected="true"] {
    background-color: #BFF8FC;
    border-radius: 8px 8px 0 0;
  }

  .accordion__panel {
    background-color: #BFF8FC;
    border-radius: 0 0 8px 8px;
    padding: 0px 14px 20px;
  }

  table {
    td {
      &:last-child {
        padding: 13px 15px;
      }

      &:nth-child(1) {
        vertical-align: top;
      }
    }
  }

  .additional-history-acc-data {
    border-top: 1px solid rgba($text-color, 0.1);
    padding-top: 5px;

    ul {
      li {

        display: flex;
        align-items: center;
        justify-content: space-between;

        label {
          font-weight: 400;
          font-size: 13px;
          line-height: 40px;
          color: rgba($text-color, 1);
        }

        span {
          font-weight: 400;
          font-size: 13px;
          line-height: 40px;
          color: rgba($text-color, 1);
        }
      }
    }
  }
}