.lead-searh-filter-outer{
    background: $white;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 24px;
    .reconcillation-filed-form{
        display: flex;
        align-items: center;
        fieldset{
            width: 230px;
            margin-right: 10px;
        }
        .btn-submit-reset {
            .btn-reset {
                background: transparent;
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                border: 0px;
                color: $heading-color;
                cursor: pointer;
                margin-left: 20px;
            }
        }
    }
}
.btn-statemnt-upload{
    display: flex;
    align-items: center;
    justify-content: center;
}

.bank-statemnt-table-outer{
    table{
        tbody{
            td{
                &:nth-child(2){
                    padding: 0px;
                }
                .sub-data{
                    tbody{
                        td{
                            
                            &:nth-child(2){
                                padding: 13px 15px;
                            }
                        }
                    }
                }
                
            }
            .v-top{
                vertical-align: top;
            }
        }
    }
    .action-btn {
        display: flex;
        li {
            height: 32px;
            width: 32px;
            border-radius: 4px;
            border: 1px solid #e0e0e0;
            margin-right: 8px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 16px;
            cursor: pointer;
            &:last-child {
                margin-right: 0px;
            }
        }
    }
    .upload-btn-txt{
        font-weight: 400;
        font-size: 15px;
        line-height: 18px;
        color: $heading-color;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 18.5px 0;
        span{
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
            color: $accent-color;
            cursor: pointer;
            padding-left: 2px;
                i{
                    font-size: 17px;
                }
        }
    }
}