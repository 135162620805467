.dashboard-outer {
  background: $white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  z-index: 1;
  position: relative;
}

.top-ribben {
  ul {
    display: flex;
    padding: 16px;

    li {
      padding-left: 44px;

      &:first-child {
        padding-left: 0px;
      }

      span {
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        color: rgba($heading-color, 0.6);
        display: block;
      }

      label {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $heading-color;
        display: flex;
        align-items: center;

        .label-title {
          font-weight: normal;
          font-size: 12px;
          line-height: 18px;
          color: $heading-color;
          max-width: 102px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        &.source-tag{
            display: flex;
            align-items: center;
          }
        }
      }

      &:nth-child(1) {
        width: 7%;
      }

      &:nth-child(2) {
        width: 11%;
      }

      &:nth-child(3) {
        width: 9%;
      }

      &:nth-child(4) {
        width: 9%;
      }

      &:nth-child(5) {
        width: 11%;
      }

      &:nth-child(6) {
        width: 10%;
      }

      &:nth-child(7) {
        width: 11%;
      }

      &:nth-child(8) {
        width: 11%;
      }

      &:nth-child(9) {
        width: 11%;
      }

      &:nth-child(10) {
        width: 11%;
      }
    }
  }
}

.finacier-paymemnt-detail {
  background: $white;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  width: 29%;
  padding: 15px;
  min-width: 29%;

  h2 {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }

  .heading {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .ic-Call-history {
      margin-left: 5px;
      font-size: 13px;
    }
  }
}

.btn-update {
  height: 23px;
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  color: $white;
  min-width: 71px;
  border-radius: 3px;
  box-shadow: none;
}

.payment-detail {
  ul {
    padding-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    li {
      padding-left: 15px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 33.33%;

      &:first-child {
        padding-left: 0px;
      }

      span {
        font-weight: normal;
        font-size: 10px;
        line-height: 15px;
        color: rgba($heading-color, 0.6);
        display: block;
      }

      label {
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $heading-color;
      }
    }
  }
}

.top-ribben-bottom {
  display: flex;
  padding-bottom: 20px;
  align-items: center;
}

.extend-option {
  padding-left: 20px;
  text-align: center;

  button {
    min-width: 117px;
    cursor: pointer;
    height: 36px;
    font-weight: 500;
    font-size: 12px;

    &.btn-primary {
      margin-bottom: 10px;
      box-shadow: none;
      line-height: 34px;
    }

    &.btn-line {
      line-height: 34px;
    }
  }

  // span {
  //   height: 34px;
  //   width: 34px;
  //   background: $accent-color;
  //   display: block;
  //   border-radius: 100%;
  //   margin: 0 auto;
  //   color: $white;
  //   font-size: 22px;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
  // label {
  //   font-weight: normal;
  //   font-size: 12px;
  //   line-height: 18px;
  //   color: $heading-color;
  //   text-decoration: underline;

  //   padding-top: 10px;
  // }
}

.dealer-tab-left-panel {
  width: 15%;
  display: inline-block;
  background: $white;
  padding-top: 20px;
  min-width: 15%;

  ul {
    li {
      margin-right: 10px;
      position: relative;

      a {
        font-weight: normal;
        font-size: 13px;
        color: $heading-color;
        padding: 15px 30px;
        background: transparent;
        border-radius: 0 25px 25px 0;
        display: block;

        &.active {
          background: #eff2f9;
          border-radius: 0 25px 25px 0;
          font-weight: 500;

          // &:after {
          //   content: "\e917";
          //   font-family: dc !important;
          //   font-size: 28px;
          //   position: absolute;
          //   right: 10px;
          //   margin-top: -5px;
          // }
          &:before {
            content: "";
            background: $accent-color;
            height: 6px;
            width: 6px;
            border-radius: 100%;
            position: absolute;
            margin-top: 6px;
            margin-left: -10px;
          }
        }
      }
    }
  }
}

.verfication-outer {
  display: flex;
  margin-top: 10px;
}

.document-verfication-process {
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  min-height: 600px;
  width: 31%;
  padding: 15px 25px;
  position: relative;
  margin-right: 20px;
  min-width: 318px;

  .accordain {
    .verfication-img {
      padding-top: 140px;
    }

    .css-yk16xz-control {
      border: 0px;
    }

    .css-2b097c-container {
      position: relative;
      box-sizing: border-box;
      border-bottom: 1px solid rgba($heading-color, 0.1);
      width: calc(100% + 50px);
      margin-left: -25px;
      padding: 0px 25px;
    }

    .css-g1d714-ValueContainer {
      padding-left: 0px;
    }

    .css-26l3qy-menu {
      margin-top: 0px;
      font-size: 12px;
      left: 0px;
    }

    .css-9gakcf-option {
      background-color: $accent-color;
    }

    .css-1pahdxg-control {
      border: 0px;
    }

    .css-18ng2q5-group {
      font-size: 12px;
      opacity: 1;
      color: rgba(0, 0, 0, 0.6);
    }

    .css-yt9ioa-option,
    .css-1n7v3ny-option,
    .css-9gakcf-option {
      padding-left: 25px;
    }

    .css-syji7d-Group {
      padding-bottom: 0px;
    }
  }

  &.input-sec {
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 0px;
    width: 36.5%;
    height: 555px;
    overflow-y: auto;
    min-width: 36.5%;
  }

  .carousel__slide {
    display: none;

    &.carousel__slide--active {
      display: block;
    }
  }
}

.dealer-panel-middle {
  display: inline-block;
  vertical-align: top;
  padding-left: 20px;
  padding-top: 10px;
  padding-right: 0px;
  width: 100%;
}

.image-opt {
  position: absolute;
  bottom: 20px;
  width: 100%;
  left: 59px;

  ul {
    display: flex;
    align-items: center;

    li {
      padding: 0px 5px;

      a {
        background: #ffffff;
        box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
        border-radius: 10px;
        width: 30px;
        height: 30px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #000;
        font-size: 20px;
        cursor: pointer;
      }
    }
  }
}

.payment-type, .remarks-type {
  font-weight: 500;
  font-size: 10px;
  line-height: 15px;
  width: calc(100% - 128px);
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;

  .pyament-type-bg {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    padding-left: 16px;

    i {
      margin-right: 0px;
    }
  }

  .ad-hoc-type {
    color: #e02020;
    background: #fbe8e8;
  }

  .normal-type {
    color: #00664f;
    background: #bdf0e5;
  }

  .advance-type {
    color: #b75f00;
    background: #ffeedb;
  }
  
  .onhold-remarks {
    color: #6642f5;
    background: #dcd5f7;
  }
  .sfap-type {
    color: #e02020;
    background: #fbe8e8;
  }
}

.put-hold-btn {
  height: 32px;
  background: #ffa340;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: $white;
  border: 0px;
  padding: 0px 7px 0px 15px;
  width: 118px;

  i {
    font-size: 21px;
    padding-left: 4px;
  }
}

.btn-outer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 18px;
}

.input-filed {
  padding-top: 20px;

  .from-group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;

    .form-label {
      font-weight: normal;
      font-size: 12px;
      line-height: 20px;
      color: rgba($heading-color, 0.7);
    }

    .form-input {
      background: $white;
      border: 1px solid #bbbbbb;
      border-radius: 6px;
      height: 38px;
      width: 120px;
      padding: 10px;
    }

    .datepicker {
      input {
        border: 1px solid #bbbbbb;
        border-radius: 6px;
        height: 38px;
        width: 120px;
        padding: 10px;
      }
    }
  }

  textarea {
    background: $white;
    border: 1px solid #bbbbbb;
    border-radius: 6px;
    height: 98px;
    width: 100%;
    padding: 10px;
  }
}

.prev-submit-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  left: 0;
  padding: 15px 20px;
  bottom: 0;

  .btn-line {
    width: calc(50% - 5px);
    min-width: auto;
  }

  .btn-primary {
    width: calc(50% - 5px);
    min-width: auto;
  }
}

.payment-sec {
  .acc-panel {
    border: 1px solid #e1e4eb;
    border-radius: 10px;
  }

  .acc-data {
    padding: 0px 20px;
  }

  .acc-heading {
    background: #e3e7f1;
    border-radius: 10px 10px 0 0;
    padding: 5px 10px 5px 20px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
    }

    .btn-primary {
      width: auto;
      box-shadow: none;
      min-width: inherit;
      padding: 0px 10px;
      height: 32px;
      line-height: 32px;
    }
  }
}

.payment-detil-form {
  &.input-filed {
    padding-top: 12px;
    margin: 0px 0 20px;

    overflow-y: auto;

    .from-group {
      .form-label {
        width: 100px;
      }
    }

    &.scroll-height {
      max-height: 199px;
      border: 1px solid #e1e4eb;
      border-radius: 10px;
      padding: 10px;
    }

    &.before-tranch {
      max-height: 380px;
      border: 1px solid #e1e4eb;
      border-radius: 10px;
      padding: 10px;
    }
  }

  .custom-control {
    min-width: 19px;
    margin-right: 5px;
  }
}

.creat-trach-bx {
  border: 1px solid #e1e4eb;
  border-radius: 10px;

  .heading-btn {
    background: #e3e7f1;
    padding: 5px 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 10px 10px 0 0;

    span {
      font-weight: 500;
      font-size: 12px;
      line-height: 18px;
      color: $heading-color;
    }

    .btn-primary {
      min-width: auto;
      height: 32px;
      font-weight: 500;
      font-size: 11px;
      line-height: 16px;
      color: white;
      padding: 0px 10px;
      border-radius: 6px;
      box-shadow: none;
    }
  }

  .input-filed {
    padding: 10px;
    max-height: 238px;
    overflow-y: auto;
    margin-bottom: 0px;
  }

  .payment-type-opt {
    width: calc(100% - 110px);

    .css-yk16xz-control,
    .css-1pahdxg-control,
    .css-1pahdxg-control:hover {
      background: transparent;
      border: 0px;
    }

    .css-9gakcf-option {
      background: $accent-color;
      white-space: nowrap;
    }

    .css-26l3qy-menu {
      width: auto;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 500;
      width: 100%;
    }

    .css-g1d714-ValueContainer {
      padding: 2px 8px 2px 0px;
    }
  }
}

.tootip-outer {
  .react-tooltip-lite {
    background: $white;
    box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
    border-radius: 6px;

    padding: 20px !important;
    // margin-left: 100px;
  }

  .react-tooltip-lite-arrow {
    border-top: 10px solid $white !important;
    // margin-left: 130px;
  }

  .controlled-example_close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
  }

  &.tooltip-payment {
    .react-tooltip-lite {
      width: 270px !important;
    }

    .payment-detail-data {

      // padding-top: 20px;
      span {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        display: block;
        color: $heading-color;
        padding-bottom: 5px;
      }

      p {
        font-weight: normal;
        font-size: 11px;
        line-height: 18px;
        color: $heading-color;
      }
    }
  }
}

.payment-type {
  .tootip-outer {
    &.tooltip-payment {
      width: 0px;
    }
  }
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 9;

  .modal-main {
    position: fixed;
    background: white;
    width: 80%;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .close_icn {
    position: absolute;
    top: 20px;
    right: 20px;
    background: none;
    border: none;
    font-size: 21px;
    cursor: pointer;
  }

  .modal-body {
    max-height: 465px;
    overflow-y: auto;
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 0px 40px;
  }
}

.overflow-hidden {
  overflow: hidden;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

.expnd-modal-outer {
  .modal-main {
    width: 550px;
    border-radius: 15px;
    padding: 40px;

    overflow-y: hidden;

    h2 {
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      color: $heading-color;
      padding-bottom: 16px;
    }

    .heading {
      padding-top: 14px;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      color: $heading-color;
      display: block;
    }

    ul {
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid rgba($heading-color, 0.06);

        &:last-child {
          border-bottom: 0px;
        }

        span {
          font-weight: normal;
          font-size: 13px;
          line-height: 45px;
          color: rgba($heading-color, 0.7);
        }

        label {
          font-weight: 500;
          font-size: 13px;
          line-height: 45px;
          color: $heading-color;
        }
      }
    }
  }
}

.payment-history-tabel {
  .modal-main {
    width: 800px;
    border-radius: 15px;
    padding: 40px;
    max-height: 600px;
    overflow-y: auto;

    h2 {
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      color: $heading-color;
      padding-bottom: 16px;
    }

    table {
      margin-top: 8px;
      table-layout: fixed;

      thead {
        tr {
          background: #e3e7f1;
          border-radius: 6px;

          th {
            padding: 18px 30px;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: $heading-color;

            &:first-child {
              border-radius: 6px 0 0 6px;
            }

            &:last-child {
              border-radius: 0px 6px 6px 0;
            }
          }
        }
      }

      tbody {
        tr {
          border-bottom: 1px solid rgba($heading-color, 0.06);

          td {
            padding: 16px 15px;
            font-weight: normal;
            font-size: 12px;
            line-height: 22px;
            color: rgba($heading-color, 0.6);
            text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &:last-child {
            border-bottom: 0px;
          }

          &:first-child {
            td {
              padding-top: 22px;
            }
          }
        }
      }

      th,
      td {
        &:nth-child(1) {
          width: 16.5%;
        }

        &:nth-child(2) {
          width: 16.5%;
        }

        &:nth-child(3) {
          width: 16.5%;
        }

        &:nth-child(4) {
          width: 16.5%;
        }

        &:nth-child(5) {
          width: 16.5%;
        }

        &:nth-child(6) {
          width: 16.5%;
        }
      }
    }
  }
}

.update-pyament-detail-form {
  .modal-main {
    width: 440px;
    border-radius: 15px;
    padding: 40px;

    h2 {
      font-weight: 500;
      font-size: 22px;
      line-height: 30px;
      color: $heading-color;
    }

    .update-detail-form {
      padding-top: 8px;
    }
  }
}

.edit-popup {
  h2 {
    padding-bottom: 16px;
  }
}

.select-list {
  position: relative;

  &:after {
    content: "\e917";
    font-family: dc !important;
    display: inline-block;
    margin-left: 10px;
    position: absolute;
    top: 12px;
    right: 13px;
    font-size: 24px;
  }
}

.update-btn {
  .btn-primary {
    width: 100%;
  }
}

.additional-remarks-outer {
  h2 {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    color: $heading-color;
  }

  .modal-main {
    width: 440px;
    border-radius: 15px;
    padding: 40px;
    max-height: 600px;
    overflow-y: hidden;
  }

  .modal {
    .modal-body {
      overflow-y: inherit;

      span {
        font-weight: normal;
        font-size: 13px;
        line-height: 30px;
        color: #666666;
        display: block;
        padding-bottom: 20px;
      }

      textarea {
        width: 100%;
        height: 118px;
        background: #ffffff;
        border: 1px solid #bbbbbb;
        box-sizing: border-box;
        border-radius: 8px;
        padding: 15px 20px;
      }

      .btn-primary {
        margin-top: 20px;
        min-width: 160px;
      }
    }
  }
}

.tootip-payment-bx {
  .react-tooltip-lite {
    background: $white;
    box-shadow: 0px 2px 14px rgba(31, 45, 78, 0.14);
    border-radius: 6px;
    width: 350px !important;
    padding: 20px !important;
    // margin-left: 100px;
  }

  .react-tooltip-lite-arrow {
    border-bottom: 10px solid $white !important;
    // margin-left: 130px;
  }

  .controlled-example_close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 18px;
  }

  .user-detail {
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 10px 0;

      span {
        font-size: 12px;
        line-height: 18px;
        width: 160px;
        padding-right: 10px;
        color: rgba($heading-color, 0.7);
      }

      label {
        font-size: 13px;
        width: calc(100% - 100px);
        font-size: 13px;
        color: $heading-color;
      }
    }
  }
}

.categories-first {
  .heading {
    padding-bottom: 12px;
  }
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-tlfecz-indicatorContainer {
  color: $heading-color !important;
}

.css-yk16xz-control {
  height: 42px;
  border-color: #bbbbbb !important;
  border-radius: 8px !important;
  min-height: 42px !important;
}

.css-1pahdxg-control {
  border-color: #bbbbbb !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  height: 42px;
  min-height: 42px !important;
}

.css-1pahdxg-control:hover {
  border-color: #bbbbbb !important;
  box-shadow: none;
  outline: none;
  height: 42px;
  border-color: #bbbbbb !important;
  border-radius: 8px !important;
  min-height: 42px !important;
}

.css-1wa3eu0-placeholder {
  font-size: 12px;
  font-weight: normal;
}

.css-tlfecz-indicatorContainer {
  padding: 4px !important;
}

.css-1gtu0rj-indicatorContainer {
  padding: 4px !important;
  color: #000 !important;

  &:hover {
    color: $heading-color !important;
  }
}

.update-detail-form {
  .css-tlfecz-indicatorContainer {
    margin-right: 13px !important;
  }

  .css-1gtu0rj-indicatorContainer {
    margin-right: 13px !important;
  }

  .datepicker {
    input {
      background: url("../../images/date.svg") no-repeat right 19px center;
    }
  }
}

.react-date-picker__button {
  background-image: url("../../images/date.svg") !important;
  height: 16px;
  width: 16px;
  position: relative;
  right: -7px;
  background-repeat: no-repeat;
}

.css-1uccc91-singleValue {
  max-width: 100% !important;
}

.css-1uccc91-singleValue {
  font-size: 12px !important;
  font-weight: 400;
}

.css-1pahdxg-control+.form-label {
  top: -25px;
}

.dealer-detail-panel-outer {
  display: flex;
}

.datepicker {
  input {
    border: 1px solid #bbbbbb;
    border-radius: 8px;
    box-shadow: 0 0 0;
    width: 100%;
    font-weight: normal;
    font-size: 12px;
    margin-bottom: 0px;
    padding: 11px 15px;
    background: url(../../images/date.svg) no-repeat right 10px center;

    &:disabled {
      background: url("../../images/date.svg") no-repeat right 19px center rgba(239, 239, 239, 0.3);
      border-color: rgba(118, 118, 118, 0.3);
    }
  }

  .react-datepicker__header__dropdown {
    padding: 15px 0 10px;
  }

  select {
    appearance: auto;
    border: 0px;
    background: transparent;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #0bbddd !important;
}

.multiselect-dropDown {

  .css-1r4vtzz,
  .css-48ayfv {
    height: 42px;
    border: 1px solid #bbbbbb !important;
    border-radius: 8px !important;
    background: transparent;
    width: 100%;
    box-shadow: none;
  }

  .css-1jllj6i-control {
    // width: 100%;

    box-shadow: none;
  }

  .css-6y1x9t-control {
    border-color: hsl(0, 0%, 80%) !important;
    box-shadow: none;

    &:hover {
      border-color: hsl(0, 0%, 80%) !important;
      box-shadow: none;
    }
  }

  .css-1v99tuv {
    font-size: 12px;
    position: relative;
    margin-top: -2px;
  }

  input {
    appearance: auto;
  }

  .css-1qprcsu-option {
    font-weight: normal;
  }

  .form-label {
    margin: -9px 0 0 15px;
    background: white;
    z-index: 9;
  }
}

.update-detail-form {
  .react-datepicker-wrapper {
    width: 100%;
  }

  .datepicker {
    margin-bottom: 20px;

    input {
      height: 52px;
    }
  }

  .datepicer {
    .material {
      .form-label {
        top: -23px;
      }
    }
  }
}

.right-data-scroll {
  height: 460px;
  overflow: auto;
  width: calc(100% + 10px);
  padding-right: 10px;
}

.disabled {
  background-color: rgba(239, 239, 239, 0.3);
  border-color: rgba(118, 118, 118, 0.3);
}

button:disabled,
button[disabled] {
  border: rgba(187, 187, 187, 0.7);
  background-color: rgba(187, 187, 187, 0.7);
  color: #666666 !important;
  box-shadow: none;
  cursor: not-allowed;
}

.required-msg {

  .form-input,
  .datepicker input,
  .css-yk16xz-control {
    border: 1px solid #ff2e2e !important;
  }
}

.error-msg {
  font-size:10px;
  margin-top: 0px;
  color: red;
}

.update-detail-form {
  .material {
    .form-input {
      margin-bottom: 0px;
    }
  }

  fieldset {
    margin-bottom: 25px;
  }

  .datepicker {
    margin-bottom: 0px;
  }
}

.required-field {
  position: absolute;
  bottom: -17px;
  font-size: 11px;
  color: #ff2e2e;
  left: 0;
  white-space: nowrap;
}

.update-pyament-detail-form {
  &.updte-form {
    .modal {
      .modal-body {
        height: 415px;
      }
    }
  }
}

.fhNQJW div {
  height: 30px !important;
  width: 30px !important;
  border-bottom: 0px !important;
  background: #fff !important;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 100%;
  margin: 0px 5px;

  svg {
    path {
      stroke: #000;
    }
  }
}

.fhNQJW {
  position: absolute;
  right: 59px !important;
  top: initial !important;
  user-select: none;
  border-radius: 0px !important;
  background: none !important;
  box-shadow: none !important;
  bottom: 20px;
  display: flex;
}

.pan-container {
  height: 270px !important;
  overflow: hidden;
}

.pdf-img {
  margin-top: 90px;
  display: block;
  text-align: center;

  img {
    width: 80px;
  }
}

.loan-table-outer {
  margin-bottom: 10px;
}

.ad-finance-popup {
  h2 {
    padding-bottom: 20px;
  }
}

.css-1gpjby2 {
  margin-left: 11px;
}

.before-tranch+.creat-trach-bx {
  .heading-btn {
    border-radius: 10px !important;
  }
}

.payment-upadate-datepicker {
  .react-datepicker__tab-loop {
    position: relative;
  }
}

.accordion-collapse {
  display: none;

  &.show {
    display: block;
  }
}

.Payment-detail-list-tranch {
  .accordion__item {
    padding: 17px 0;
    border-bottom: 1px solid rgba($heading-color, 0.1);
    position: relative;

    .payment-checked-select {
      position: absolute;
      right: -10px;
      top: 23px;
      z-index: 9;

      .custom-control-label {
        &:before {
          height: 14px;
          width: 14px;
        }

        &:after {
          left: 1px;
        }
      }
    }

    &:last-child {
      border-bottom: 0px;
    }
  }

  .accordion__button {
    background: transparent;
    border: 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    display: flex;
    align-items: center;
    color: $heading-color;

    &:before {
      content: "\e907";
      font-family: dc !important;
      font-size: 20px;
      bottom: 1px;
      margin-right: 20px;
    }
  }

  .accordion__button[aria-expanded="true"]::before,
  .accordion__button[aria-selected="true"]::before {
    content: "\e90c";
    font-family: dc !important;
  }

  .status-txt {
    font-weight: 500;
    font-size: 10px;
    line-height: 15px;
    color: $white;
    border-radius: 3px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 9px;
    position: absolute;
    right: 0px;
    top: 22px;
  }

  .status-approve {
    background: #3bc04d;
  }

  .status-update {
    background: #3aa1f9;
  }

  .status-checking {
    background: #a5a5a5;
  }

  .status-sent-approver {
    background: #a5a5a5;
  }

  .from-group {
    &:last-child {
      padding-bottom: 0px;
    }
  }

  .payment-detil-form.input-filed {
    margin-bottom: 0px;
  }
}

.image-varification-slider {
  .pfd-img-height {
    >div {
      >div {
        display: flex;
        top: inherit !important;
        bottom: 20px;
        background: transparent !important;
        box-shadow: none !important;
        border: 0px !important;
        right: 15px !important;

        >div {
          border-radius: 100%;
          background: $white;
          width: 30px !important;
          height: 30px !important;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 5px;

          svg {
            height: 18px !important;
            width: 18px !important;
            padding: 0px !important;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;

            path,
            polygon {
              stroke: #000;
            }
          }
        }
      }
    }

    .pan-container {
      >div {
        height: auto !important;
        width: auto !important;
        margin: 0px !important;
      }
    }
  }

  .image-opt {
    left: 0px;
    padding: 0px 20px 0px 25px;
  }
}

.required-msg.multiselect-dropDown .css-1r4vtzz,
.required-msg.multiselect-dropDown .css-48ayfv {
  border: 1px solid red !important;
}

.access-denied-container {
  display: flex;
  height: calc(100vh - 50px);
  width: 100%;
  align-items: center;
  justify-content: center;

  h1 {
    font-size: 30px;
  }
}

.search-outer {
  text-align: right;
  float: right;
  position: relative;

  .form-input {
    padding: 8px;
    border-radius: 8px;
    border: 1px solid #8c8c8c;
  }

  .ic-search {
    position: absolute;
    right: 5px;
    top: 6px;
    font-size: 24px;
  }
}

.active-dot:before {
  content: "";
  background: #0bbddd;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  margin-right: 2px;
  display: inline-block;
}

.inactive-dot:before {
  content: "";
  background: #e02020;
  height: 8px;
  width: 8px;
  border-radius: 100%;
  margin-right: 2px;
  display: inline-block;
}

.react-datepicker-popper {
  z-index: 10 !important;
}

.fam-submition {
  .custom-control-label::before {
    top: -1px;
    left: -2px;
  }
}

.width {
  width: 100% !important;
}

.view-timeline-popup {
  .modal-body {
    max-height: 450px;
    overflow-y: hidden;
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 0px 40px;
  }

  .modal-main {
    border-radius: 8px;
    width: 530px;
    padding: 40px 40px;

    h2 {
      padding: 0px 0 24px 0px;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    }
  }

  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 10px;

    tbody {
      tr {
        background: #eff2f9 !important;

        td {
          font-size: 11px;
          line-height: 18px;
          color: rgba($heading-color, 0.6);
          padding: 14px 20px;

          &:nth-child(1) {
            width: 80px;
            padding-left: 0px;
            background: $white;
          }

          &:nth-child(2) {
            width: calc(100% - 80px);
            border-radius: 8px;
          }

          &:nth-child(3) {
            border-radius: 0 8px 8px 0;
          }

          .date {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: $heading-color;
            display: block;
          }

          .time {
            font-size: 10px;
            line-height: 20px;
            color: rgba($heading-color, 0.6);
            display: block;
          }

          .heading {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: $heading-color;
            display: block;
            font-weight: 400;
            word-break: break-all;
          }

          .subheading {
            font-size: 11px;
            line-height: 18px;
            color: rgba($heading-color, 0.6);
            display: block;
          }
        }

        &:hover {
          background: #bff8fc !important;
        }
      }
    }
  }
}

.image-timeline-popup {
  .modal-body {
    overflow-y: auto;
  }

  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 10px;

    tbody {
      tr {
        background: #eff2f9;

        td {
          font-size: 11px;
          line-height: 18px;
          color: rgba($heading-color, 0.6);
          padding: 14px 20px;

          &:nth-child(1) {
            width: 80px;
            padding-left: 0px;
            background: $white;
          }

          &:nth-child(2) {
            width: 230px;
            border-radius: 8px 0 0 8px;
          }

          &:nth-child(3) {
            border-radius: 0 8px 8px 0;
          }
        }
      }
    }
  }
}

.text-danger {
  text-align: center;
  color: red;
}

.add-remarks{
  padding: 10px 0 0 0;
  h1 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .material .form-input{
    margin-bottom: 5px;
  }
}

.input-none {
  position: relative;

  .override_input {
    position: absolute;
    left: 0px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    background: #000;
    height: 100%;
    border-radius: 6px;
    color: #fff;
    /* display: flex; */
    /* align-items: center; */
    /* justify-content: center; */
    padding: 9px 5px;
  }
}
.single-select {
  .form-label:before {
    margin: -25px 0 0 15px;
    background: white;
    z-index: 9;
  }
}

.input-none-outer {
  display: flex;
  align-items: center;

  .form-input {
    width: 98px !important;
  }

  .input-none {
    margin-right: 8px;
  }
}

.disbursed-amount-loader{
  position: relative;
  .loader {
    &.is-active{
      position: absolute !important;
      top:0;
      height: auto !important;
    }
  }
}
.readonly-container {
  pointer-events: none;
  background: #cccccc47;
}
.sync-btn {
  position:absolute;
  right: 0;
  display: flex;
  align-items: center;
  top: 24px;
  .ic-refresh{
    font-size: 20px;
    color: $heading-color;
    cursor: pointer;
    margin-right: 15px;
  }
  .status-txt{
    position:initial;
  }
  .lost-btn{
    margin-right: 22px;
    width: 37px;
  }
}

.react-datepicker-wrapper{
  width: 100%;
}

.nowrap{
  white-space: nowrap;
}

.dealer-incentive-table{
  overflow-x: auto;
  table {
    tr {
      th, td {
        &:nth-child(1) {
          // padding-left: 40px;
          // width: 90px;
          width: 120px;
        }
        &:nth-child(2) {
          // padding-left: 40px;
          width: 80px;
        }
        &:nth-child(3) {
          padding-left: 40px;
          width: 195px;
        }
        &:nth-child(4) {
          // padding-left: 40px;
          width: 110px;
        }
        &:nth-child(5) {
          // padding-left: 40px;
          // width: 155px;
          width: 110px;
        }
        &:nth-child(6) {
          // padding-left: 40px;
          // width: 110px;
          width: 165px;
        }
        &:nth-child(7) {
          // padding-left: 40px;
          width: 115px;
          // width: 165px;
        }
        &:nth-child(8) {
          // padding-left: 40px;
          width: 150px;
        }
        &:nth-child(9) {
          // padding-left: 40px;
          width: 160px;
        }
        &:nth-child(10) {
          // padding-left: 40px;
          width: 160px;
        }
        &:nth-child(11) {
          // padding-left: 40px;
          width: 155px;
        }
        &:nth-child(12) {
          // padding-left: 40px;
          width: 165px;
        }
        &:nth-child(13) {
          // padding-left: 40px;
          // width: 180px;
          width: 165px;
        }
        &:nth-child(14) {
          // padding-left: 40px;
          width: 155px;
        }
        &:nth-child(15) {
          // padding-left: 40px;
          // width: 130px;
          width: 156px;
        }
        &:nth-child(16) {
          // padding-left: 40px;
          width: 130px;
        }
        &:nth-child(17) {
          // padding-left: 40px;
          width: 160px;
        }
        &:nth-child(18) {
          // padding-left: 40px;
          width: 130px;
        }
        &:nth-child(19) {
          // padding-left: 40px;
          width: 130px;
        }
        &:nth-child(20) {
          // padding-left: 40px;
          width: 130px;
        }
        &:nth-child(21) {
          // padding-left: 40px;
          width: 130px;
        }
      }
    }
  }
}
.inbound-remark-history.view-timeline-popup {
  .modal-body {
    max-height: 450px;
    overflow-y: hidden;
    width: calc(100% + 80px);
    margin-left: -40px;
    padding: 0px 40px;
  }

  .modal-main {
    border-radius: 8px;
    width: 530px;
    padding: 40px 40px;

    h2 {
      padding: 0px 0 24px 0px;
      font-weight: 600;
      font-size: 22px;
      line-height: 30px;
    }
  }

  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 10px;

    tbody {
      tr {
        background: #eff2f9;

        td {
          font-size: 11px;
          line-height: 18px;
          color: rgba($heading-color, 0.6);
          padding: 14px 20px;

          &:nth-child(1) {
            width: 80px;
            padding-left: 0px;
            background: $white;
          }

          &:nth-child(2) {
            width: calc(100% - 80px);
            border-radius: 8px;
          }

          &:nth-child(3) {
            border-radius: 0 8px 8px 0;
          }

          .date {
            font-weight: normal;
            font-size: 12px;
            line-height: 20px;
            color: $heading-color;
            display: block;
          }

          .time {
            font-size: 10px;
            line-height: 20px;
            color: rgba($heading-color, 0.6);
            display: block;
          }

          .heading {
            font-weight: normal;
            font-size: 13px;
            line-height: 20px;
            color: $heading-color;
            display: block;
            font-weight: 400;
            word-break: break-all;
          }

          .subheading {
            font-size: 11px;
            line-height: 18px;
            color: rgba($heading-color, 0.6);
            display: block;
          }
        }

        &:hover {
          background: #bff8fc;
        }
      }
    }
  }
}

.inbound-remark-history.image-timeline-popup {
  .modal-body {
    overflow-y: auto;
  }

  table {
    table-layout: fixed;
    border-collapse: separate;
    border-spacing: 0 10px;

    tbody {
      tr {
        background: #eff2f9;

        td {
          font-size: 11px;
          line-height: 18px;
          color: rgba($heading-color, 0.6);
          padding: 14px 20px;

          &:nth-child(1) {
            width: 80px;
            padding-left: 0px;
            background: $white;
          }

          &:nth-child(2) {
            width: 230px;
            border-radius: 8px 0 0 8px;
          }

          &:nth-child(3) {
            border-radius: 0 8px 8px 0;
          }
        }
      }
    }
  }
}

.pdf-img {
  margin-top: 90px;
  display: block;
  text-align: center;

  img {
    width: 80px;
  }
}

.pdf-img {
  margin-top: 90px;
  display: block;
  text-align: center;

  img {
    width: 80px;
  }
}

.v-top{
  vertical-align: top !important;
}