.additional-revenue-popup {
  &.update-pyament-detail-form {
    .modal-main {
      width: 768px;

    }
  }

  .revenue-top-ribben {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba($text-color, 0.1);
    padding-bottom: 20px;
    padding-top: 10px;
    margin-bottom: 30px;

    ul {
      display: flex;
      align-items: center;
      justify-content: center;
      width: calc(100% - 20%);

      li {
        width: 20%;
        padding-right: 20px;

        label {
          display: block;
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          color: rgba($text-color, 0.6);
        }

        span {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: $text-color;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
          white-space: nowrap;
        }
      }
    }

    .btn-line {
      display: flex;
      align-items: center;
      justify-content: center;

      .ic-add {
        font-size: 20px;
        margin-right: 5px;
      }
    }
  }

  .additional-revenue-form-filed {
    margin-bottom: 20px;
    .additional-revenue-form-items{
      display: none;
    }
    .additional-revenue-form-items.active{
      display: block;
    }
    .material {
      .form-label {
        &:before {
          top: 14px;
        }
      }

      .form-input {
        height: 48px;

        &:focus,
        &:visited,
        &:focus-within,
        &:focus-visible {
          ~label {
            &:before {
              top: -9px;
              font-size: 12px;
            }
          }
        }

        &:not(:placeholder-shown) {
          ~label {
            &:before {
              top: -9px;
              font-size: 12px;
            }
          }
        }
      }
    }

    .datepicker {
      input {
        padding: 14px 15px;
      }
    }
  }

  .revenue-submit-btn {
    display: flex;
    width: 100%;
  }

  .add-split-bx {
    ul {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;

      li {
        background: $white;
        padding: 6px 6px 6px 17px;
        font-weight: normal;
        font-size: 12px;
        line-height: 18px;
        color: $heading-color;
        display: flex;
        align-items: center;
        border-radius: 16px;
        margin-right: 8px;
        border: 1px solid #dde1e9;
        margin-bottom: 10px;
        position: relative;
        cursor: pointer;
        span {
          background: rgba(227, 231, 241, 0.6);
          height: 20px;
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 9px;
          border-radius: 12px;
          cursor: pointer;
        }
        &.pointer-none{
          pointer-events: none;
          opacity: 0.6;
        }
        &.error{
          background: #e02020;
        }
        &.active {
          border-color: $accent-color;

          &::before {
            content: "";
            height: 3px;
            background-color: #fff;
            width: 15px;
            position: absolute;
            bottom: -2px;
            left: 0px;
            right: 0px;
            margin: 0 auto;
          }

          &::after {
            content: "";
            position: absolute;
            width: 12px;
            height: 12px;
            border-bottom: 1px solid $accent-color;
            border-right: 1px solid $accent-color;
            transform: rotate(45deg) skew(10deg, 10deg);
            left: 0px;
            bottom: -7px;
            right: 0px;
            margin: 0 auto;
          }
        }
      }
    }
  }


  .split-amt-sec {
    display: flex;
    align-items: center;
    margin-top: 20px;
    justify-content: space-between;
    background: #fee7e7;
    border: 1px solid #efd3d3;
    border-radius: 8px;
    padding: 0px 20px;
    width: 100%;


    ul {
      display: flex;
      align-items: center;
      padding: 13px 30px 13px 0;
      border-right: 1px solid #C0E7CC;
      width: calc(100% - 36%);
      overflow-x: auto;
      white-space: nowrap;

      li {
        padding: 0px 10px;

        label {
          font-weight: normal;
          font-size: 11px;
          line-height: 16px;
          display: block;
          color: rgba($heading-color, 0.6);
        }

        span {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #3e3e3e;
          display: block;
        }
      }
    }

    .remaing-amt {
      font-weight: 500;
      font-size: 13px;
      line-height: 19px;
      padding: 0px 20px;

      span {
        display: block;
        color: #cd291b;
      }
    }

    &.remainig-amt-nill {
      background: #e8ffef;
      border: 1px solid #c0e7cc;
      border-radius: 8px;

      ul {
        border-right: 1px solid #c0e7cc;
      }

      .remaing-amt {
        font-weight: 500;
        font-size: 13px;
        line-height: 19px;
        color: #1c9e40;
        padding: 0px 20px;
      }
    }
  }


  .react-select__menu-list {
    max-height: 80px !important;
  }


}