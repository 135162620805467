.button {
  height: 42px;
  line-height: 42px;
  text-align: center;
  font-size: 12px;
  @include border-radius(8px);
  display: inline-block;
  font-weight: 500;
  cursor: pointer;
}

.btn-gps {
  background: $accent-color;
  color: #fff;
  @include border-radius(12.5px);
  padding: 6px 10px;
  font-size: 13px;

  i {
    color: #fff;
    vertical-align: middle;
    margin-right: 4px;
  }
}

.btn-line {
  @extend .button;
  border: 1px solid $promotional-color;
  color: $heading-color;
  min-width: 105px;
  background: #fff;
  &:hover {
    background: $accent-color;
    color: $white;
    border-color: $accent-color;
  }
}

.btn-solid {
  @extend .button;
  background: $promotional-color;
  border: 0;
  width: 100%;
  color: #fff;
}

.btn-edit-delete {
  font-size: 12px;
  color: $heading-color;
  height: 29px;
  line-height: 28px;
  padding: 0px 20px;
  border: 1px solid #dbdbdb;
  background: $white;
  color: $heading-color;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background: $accent-color;
  box-shadow: 0px 6px 6px rgba(11, 189, 221, 0.25);
  border-radius: 8px;
  height: 44px;
  line-height: 44px;
  font-weight: 500;
  font-size: 14px;
  color: $white;
  cursor: pointer;
  min-width: 200px;
  border: 1px solid $accent-color;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
  input { 
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }
  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 6px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }
  input:checked + .slider {
    background-color: #2196F3;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider.round {
    border-radius: 34px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
}
.lost-btn {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #fff;
  background: #ff8a8a;
  border-radius: 4px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 13px;
  border: 1px solid #ff8a8a;
  cursor: pointer;
}
.btn-with-loader{
  position: relative;
  .loader.is-active {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
  }
}

.btn-verify {
  background: rgb(53, 175, 53);
  box-shadow: 0px 6px 6px rgba(11, 189, 221, 0.25);
  border-radius: 8px;
  height: 35px;
  line-height: 35px;
  font-weight: 500;
  font-size: 12px;
  color: $white;
  cursor: pointer;
  min-width: 100px;
  border: 1px solid $accent-color;
}

.btn-add {
  background: $accent-color;
  box-shadow: 0px 6px 6px rgba(11, 189, 221, 0.25);
  border-radius: 8px;
  height: 35px;
  line-height: 35px;
  font-weight: 500;
  font-size: 12px;
  color: $white;
  cursor: pointer;
  min-width: 100px;
  border: 1px solid $accent-color;
}
.btn-cancel {
  background: $white;
  box-shadow: 0px 6px 6px rgba(11, 189, 221, 0.25);
  border-radius: 8px;
  height: 35px;
  line-height: 35px;
  font-weight: 500;
  font-size: 12px;
  color: #000;
  cursor: pointer;
  min-width: 100px;
  border: 1px solid #000;
  transition: background-color 0.3s, border-color 0.3s, color 0.3s;
}

.btn-cancel:hover {
  background: $accent-color;
  border-color: $accent-color;
  color: white;
}

.verifid {
  color: rgb(49, 117, 49);
  min-width: 100px;
}
.verifid-faild {
  color: rgb(255, 60, 0);
  min-width: 100px;
}
.customer-mobile .action-btn li {
  height: 32px;
  width: 32px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  cursor: pointer;
}

.customer-mobile .action-btn .ic-check {
  color: #0bbddd;
}
.customer-mobile .action-btn .ic-clearclose {
  color: #e02020;
}
.customer-mobile .action-btn {
  display: flex;
}
.customer-mobile .form-input { width: 150px!important;}
.customer-mobile .action-btn .ic-createmode_editedit {
  color: #0bbddd;
}

.report-sort{
  width: 200px;
}