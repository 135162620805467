.reconcilliation-tab-outer {
    .reconcilliation-tab-bg {
        background: $white;
        border-radius: 8px;
        padding: 16px 20px 18px;
    }

    h2 {
        font-size: 16px;
    }

    .tab-list {
        display: flex;
        align-items: center;

        .nav-item {
            margin-right: 50px;
            cursor: pointer;

            .nav-link {
                border-bottom: 2px solid transparent;
                padding: 0px 20px 8px 20px;
                font-weight: normal;
                font-size: 16px;
                line-height: 20px;
                color: rgba($heading-color, 0.87);

                &.active {
                    font-weight: 600;
                    color: rgba(11, 189, 221, 0.87);
                    border-bottom: 2px solid #0bbddd;
                }
            }
        }
    }


}

.bulk-match-btn {
    min-width: 120px;
}

.reconcillation-filter-outer {
    background: $white;
    border-radius: 8px;
    padding: 20px;
    margin: 10px 0;

    .reconcillation-filed-form {
        display: flex;
        align-items: center;

        .search-bx {
            position: relative;
            width: 160px;

            .material {

                .form-input {

                    margin-bottom: 0px;
                    padding: 10px 15px;
                    padding-left: 45px;
                }

                i {
                    position: absolute;
                    left: 15px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 23px;
                    color: #000;
                    font-weight: normal;
                }
            }
        }

        fieldset {
            margin-right: 8px;
            width: 151px;
        }

        .btn-primary {
            min-width: 130px;
        }

        .Date-picker-sec {
            width: 130px;
            margin-right: 10px;
            fieldset {
                width: 100%;
            }
    
        }
    }

    .btn-submit-reset {
        display: flex;
        align-items: center;
        .btn-reset {
            background: transparent;
            font-weight: 500;
            font-size: 12px;
            line-height: 18px;
            border: 0px;
            color: $heading-color;
            cursor: pointer;
            margin-left: 10px;
        }
    }

    .selected-chips {
        ul {
            display: flex;
            align-items: center;
            padding: 10px 0 0 0;
            flex-wrap: wrap;

            li {
                background: $white;
                padding: 6px 6px 6px 17px;
                font-weight: normal;
                font-size: 12px;
                line-height: 18px;
                color: $heading-color;
                display: flex;
                align-items: center;
                border-radius: 16px;
                margin-right: 8px;
                border: 1px solid #dde1e9;
                margin-top: 10px;

                span {
                    background: rgba(227, 231, 241, 0.6);
                    height: 20px;
                    width: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 9px;
                    border-radius: 12px;
                    cursor: pointer;
                }
            }
        }
    }
}

.reconcillation-count-data {
    background: $white;
    border-radius: 8px;
    padding: 20px 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;

    ul {
        display: flex;
        align-items: center;

        li {
            text-align: center;
            margin-right: 50px;
            padding-bottom: 17px;
            border-bottom: 2px solid transparent;

            span {
                font-weight: 400;
                font-size: 11px;
                line-height: 16px;
                color: rgba($heading-color, 0.6);
                display: block;
            }

            label {
                font-weight: 600;
                font-size: 24px;
                line-height: 36px;

                &.total-entries-txt {
                    color: $heading-color;
                }

                &.matched-txt {
                    color: #34C48B;
                }

                &.not_matched {
                    color: #F83939;
                }

                &.duplicates-txt {
                    color: #FFA340;
                }

                &.matched_manually-txt {
                    color: #4891FF;
                }

                &.matched_duplicate-txt {
                    color: #deb887;
                }

                &.bulk_matched-txt {
                    color: #B47414;
                }
            }

            &.active {
                border-bottom: 2px solid #0bbddd;
            }
        }
    }
}

.reconcillation-table-outer {
    background: $white;

    table {
        table-layout: fixed;

        thead {
            tr {
                background: #e3e7f1;
                border-radius: 6px;

                th {
                    padding: 15px 15px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: $heading-color;
                    white-space: nowrap;
                    text-align: center;
                    width: 375px;
                    padding: 15px 0 0 0;
                    vertical-align: top;


                    &:first-child {
                        border-radius: 8px 0 0 0px;
                    }

                    &:last-child {
                        border-radius: 0px 8px 0px 0;
                        width: 80px;
                    }

                    &:nth-child(4) {
                        width: 100px;
                    }

                    &:nth-child(3) {
                        width: 327px;
                    }

                    span {
                        font-size: 12px;
                        line-height: 22px;
                        color: $heading-color;
                        white-space: nowrap;
                        vertical-align: top;
                        height: 30px;
                    }

                    .sub-data-heading {
                        margin-top: 8px;

                        tbody {
                            tr {
                                background: rgba(255, 255, 255, 0.4);

                                td {
                                    font-weight: 400;
                                    font-size: 12px;
                                    line-height: 18px;
                                    color: $heading-color;
                                    padding: 13px 15px;

                                    &:last-child {
                                        border-right: 1px solid $border-color;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid rgba($heading-color, 0.06);

                td {
                    padding: 13px 15px;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: rgba($heading-color, 0.6);
                    text-align: left;
                    padding: 0px 0;

                    a {
                        color: rgba($heading-color, 0.6);
                    }

                    &:nth-child(4) {
                        width: 100px;
                    }

                    &:nth-child(5) {
                        width: 100px;
                    }

                    .sub-data-heading {
                        td {
                            padding: 13px 15px;
                            white-space: nowrap;

                            &:last-child {
                                border-right: 1px solid $border-color;
                            }
                        }
                    }
                }

                &:last-child {
                    border-bottom: 0px;
                }


            }
        }
    }

    .matched-txt {
        color: #34C48B;
        text-align: center;
        display: block;
        padding: 0px 13px;
    }

    .not_matched-txt {
        color: #F83939;
        text-align: center;
        display: block;
        padding: 0px 13px;
    }

    .duplicate-txt {
        color: #FF8400;
        text-align: center;
        display: block;
        padding: 0px 13px;
    }

    .matched_manually-txt {
        color: #4891FF;
        text-align: center;
        display: block;
        padding: 0px 13px;
    }

    .bulk_matched-txt {
        color: #B47414;
        text-align: center;
        display: block;
        padding: 0px 13px;
    }

    .action-txt {
        text-decoration: underline;
        text-align: center;
        cursor: pointer;
        padding: 0px 13px;
    }
}

.truncate {
    overflow: hidden;
    text-overflow: ellipsis;
}


.match-record-popup {
    &.update-pyament-detail-form {
        .modal-main {
            width: 1070px;
        }
        .modal{ 
        .modal-body{
            max-height: 600px;
        }}
        .from-group {
            display: flex;

            .custom-control {
                margin-right: 40px;
                .custom-control-input{
                    width: 100%;
                }
            }

            .custom-control-label {
                font-size: 12px;
                color: rgba($heading-color, 0.6);
            }

            .custom-control-label::before {
                top: -3PX;
            }

            .custom-control-label::after {
                top: -1px;
            }

            .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
                font-size: 21px;
            }




        }
    }

    .sub-heading {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: rgba($heading-color, 0.87);
        display: block;
        margin-top: 26px;
        margin-bottom: 20px;
    }

    .matching-data-table {
        margin-top: 40px;
        display: flex;
        justify-content: space-between;

        .heading {
            font-weight: 500;
            font-size: 16px;
            color: rgba($heading-color, 0.87);
            margin-bottom: 10px;
            display: block;
        }

        .matching-data-table-left {

            max-width: 480px;

            ul {
                border: 1px solid $border-color;
                border-radius: 8px;
                // padding: 0px 20px;
                max-height: 343px;
                overflow-y: auto;

                li {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 18px;
                    color: rgba($heading-color, 0.6);
                    padding: 10px;
                    border-bottom: 1px solid $border-color;
                    cursor: pointer;
                    &:last-child {
                        margin-bottom: 30px;
                    }
                    &.active-li{
                        background-color: #0bbddd;
                    }
                }
            }

        
        
        
        
        }

  

     .matching-table-right{
        margin-left: 30px;
        width: 480px;
        .matching-table-right-top{
           
                 }
         table{
            table-layout: fixed;
            border-left: 1px solid $border-color;;
            border-right: 1px solid $border-color;
             thead{
                 tr{
                    background: #e3e7f1;
                    display: table;
                    width: 100%;
                    table-layout: fixed;
                 th{
                    padding: 10px 15px;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 22px;
                    color: $heading-color;
                    white-space: nowrap;
                    text-align: left;
                    position: sticky;
                    top: 0;
                    &:first-child {
                        border-radius: 6px 0 0 0px;
                    }
                    &:last-child{
                        border-radius: 0px 6px 0px 0;
                    }
                 
                 }
                }
             }
             tbody{
                 display: block;
                 width: 100%;
                 height: 100px;
                 overflow-y: auto;
                 tr{
                     border-bottom: 1px solid $border-color;
                    
                     display: table;
                     width: 100%;
                     table-layout: fixed;
                     td{
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: $heading-color;
                        padding: 10px 15px;
                     }
                 }
             }
         }
     }
    }
    .btn-match-record{
        float: right;
    }
}